import {
  DatePickerWithInput,
  Pagination,
  // Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import usePercentageReport from "./usePercentageReport";
import Select from "react-select";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";

const PercentageReport = () => {
  const {
    date,
    formik,
    basicData,
    tableData,
    isFetching,
    reportState,
    paginationOptions,
    getRow,
    handleSort,
    handlePageSize,
    handleDateChange,
    handlePagination,
    handleShopChange,
    handleBranchFilter,
    handleDownloadClick,
  } = usePercentageReport();

  return (
    <div className="">
      <h3>Percentage Report </h3>
      <div className=" pro-mt-5 row gx-2 pro-items-end">
        <div className={`col-auto`}>
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Date Range
          </label>
          <DatePickerWithInput
            CalendarOutlineIcon={
              <span className="material-symbols-outlined">calendar_month</span>
            }
            range={true}
            initialValue={date}
            onDateChange={handleDateChange}
          ></DatePickerWithInput>
        </div>
        <div className="col-2">
          <div className="input-wrap">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Type
            </label>
            <Select
              id="type"
              placeholder={"Select"}
              className={"pro-input lg"}
              classNamePrefix="pro-input"
              name="type"
              options={basicData?.data?.shop_or_supplier_type}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.name}
              value={formik?.values?.type}
              onChange={(value) => {
                formik.setFieldValue("type", value);
                formik?.setFieldValue("shop_supplier", "");
              }}
              menuPlacement="auto"
            />
          </div>
        </div>
        <div className="col-2">
          <div className="input-wrap">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Shop/Supplier
            </label>
            <Select
              id="shop_supplier"
              placeholder={"Select"}
              className={"pro-input lg"}
              classNamePrefix="pro-input"
              name="shop_supplier"
              options={
                formik?.values?.type?.id === 1
                  ? basicData?.data?.shop_list
                  : formik?.values?.type?.id === 2
                  ? basicData?.data?.supplier_list
                  : []
              }
              getOptionValue={(option) => option?._id}
              getOptionLabel={(option) => option?.name}
              value={formik?.values?.shop_supplier}
              onChange={(value) => handleShopChange(value)}
              menuPlacement="auto"
            />
          </div>
        </div>
        <div className="col-3">
          <div className="input-wrap ">
            <label
              htmlFor="branch"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Branch
            </label>
            <Select
              id="branch"
              placeholder={"Select"}
              className={"pro-input lg"}
              classNamePrefix="pro-input"
              isMulti
              name="branch"
              options={basicData?.data?.branch_list}
              getOptionValue={(option) => option?._id}
              getOptionLabel={(option) => option?.branch_name}
              value={basicData?.data?.branch_list?.filter((p) =>
                reportState?.percentageReport?.currentBranchFilter?.includes(
                  p._id
                )
              )}
              onChange={(value) => handleBranchFilter(value?.map((v) => v._id))}
              menuPlacement="auto"
            />
          </div>
        </div>
        <div className="col-auto pro-ms-auto">
          <button
            className="pro-btn pro-btn-primary lg"
            onClick={handleDownloadClick}
            disabled={
              !(formik?.values?.shop_supplier?._id && formik?.values?.type?.id)
            }
          >
            <span class="material-symbols-outlined x2">download</span>
            <span>Export</span>
          </button>
        </div>
      </div>
      <div>
        {tableData?.data?.data?.length > 0 ? (
          <>
            <Table
              multiSelect={false}
              data={tableData?.data?.data || []}
              uniqueID={"id"}
              fields={tableData?.data?.fields}
              SortIcon={<FaSort />}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={reportState?.percentageReport.currentPageSize}
              assignable={false}
              editable={false}
              deletable={false}
              showCheckBox={false}
            />
            {/* uncommend after backed added pagination */}
            {tableData?.data?.data?.length > 0 && (
              <Pagination
                currentPage={reportState?.percentageReport.currentPage}
                totalPageCount={Math.ceil(
                  tableData.data.total_count /
                    reportState.percentageReport.currentPageSize
                )}
                onPageChange={handlePagination}
                options={paginationOptions}
                onActionChange={handlePageSize}
                center
              />
            )}
          </>
        ) : (
          <EmptyData />
        )}
      </div>
    </div>
  );
};

export default PercentageReport;
