import { getAxiosInstance } from "../../../api";

export const getInvoiceNumberData = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/invoice-number-format/edit?invoice_number_format_id=${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};


export const deleteInvoiceNumber = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete(`/invoice-number-format/delete`, {
      data: body,
    });
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const addInoviceNumberData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/invoice-number-format/save`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const editInoviceNumberData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/invoice-number-format/update`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const approveInvoiceData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/invoice-number-format/approve`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
