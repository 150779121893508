import { useFormik } from "formik";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { updatePrintingForm } from "../api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Printing/printingSlice";

const useShowForm = () => {
  const dispatch = useDispatch();
  const { formData, selectedListId, activeId, data } = useSelector((state) => state.printing);

  let dynamicStructure = {};
  useEffect(() => {
    // Iterate over the qc_fields array unconditionally
    if (formData?.length !== 0) {
      for (const field of formData?.qc_fields) {
        const slug = field?.slug;
        dynamicStructure[slug] = "";
      }
      // Check if cstone_sstone is 1 before adding center_stone_fields and side_stone_fields
      if (formData.cstone_sstone === 1) {
        for (const field of formData?.center_stone_fields) {
          const slug = field?.slug;
          dynamicStructure[`center_stone_${slug}`] = "";
        }

        for (const field of formData?.side_stone_fields) {
          const slug = field?.slug;
          dynamicStructure[`side_stone_${slug}`] = "";
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);
  useEffect(() => {
    if (formData) {
      formik?.setErrors({});
      // eslint-disable-next-line array-callback-return
      formData?.qc_fields?.map((value) => {
        formik?.setFieldValue(value?.slug, value?.value ?? "");
      });
      // eslint-disable-next-line array-callback-return
      formData?.center_stone_fields?.map((value, index) => {
        formik?.setFieldValue(
          `center_stone_${value?.slug}`,
          value?.value ?? ""
        );
      });
      // eslint-disable-next-line array-callback-return
      formData?.side_stone_fields?.map((value, index) => {
        formik?.setFieldValue(`side_stone_${value?.slug}`, value?.value ?? "");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const formik = useFormik({
    initialValues: dynamicStructure,
    // validate: (values) => {
    //   let errors = {};
    //   Object.keys(values || {}).forEach((key) => {
    //     if (
    //       values[key] === null ||
    //       values[key] === undefined ||
    //       values[key] === ""
    //     ) {
    //       errors[`${key}`] = `This field is required`;
    //     }
    //   });
    //   return errors;
    // },
    onSubmit: async (values) => {
      let data = {
        item_detail_id: selectedListId,
        ...values,
      };

      let formDatas = new FormData();
      for (let key in data) {
        if (key.startsWith("center_stone")) {
          const formattedKey = key.replace("center_stone_", ""); // Remove the prefix
          formDatas.append(`center_stone[${formattedKey}]`, data[key]);
        } else if (key.startsWith("side_stone")) {
          const formattedKey = key.replace("side_stone_", ""); // Remove the prefix
          formDatas.append(`side_stone[${formattedKey}]`, data[key]);
        } else {
          formDatas.append(key, data[key]);
        }
      }
      const response = await updatePrintingForm(formDatas);
      try {
        if (response?.data?.status_code === 200) {
          toast.success(response?.data?.message);
          dispatch(
            updateConfig((state) => {
              state.showForm = false;
            })
          );
          formik?.resetForm();
          formik?.setErrors({});
        } else if (response?.status_code === 422) {
          let errors = response?.errors;
          let errorFields = Object.keys(errors);
          errorFields.forEach((field) => {
            formik.setFieldError(field, errors[field]);
          });
        } else {
          toast.error(`Something went wrong: Please try again`);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik?.touched?.[fieldName] && formik?.errors?.[fieldName]) {
      return formik?.errors?.[fieldName];
    }
    return "";
  };

  const handleAdd = () => {
    dispatch(
      updateConfig((state) => {
        const currentVersions = state.data.map((item) => item.name);
        let versionNumber = 1;
        while (currentVersions.includes(`Version ${versionNumber}`)) {
          versionNumber++;
        }
        const newVersionName = `Version ${versionNumber}`;

        // Disable all existing versions
        state.data.forEach((item) => {
          item.disabled = true;
        });

        // Add the new version and enable it
        state.data.push({ name: newVersionName, disabled: false });
        state.activeId = state.data.length; // Set the activeId to the index of the newly added version.
      })
    );
  };

  const handleClick = (e) => {
    if (e.name === "Version 1") {
      dispatch(updateConfig((state) => (state.activeId = 1)));
    } else {
      const inputString = e?.name;
      const numericPart = inputString.match(/\d+/);
      const numericValue = parseInt(numericPart[0]);
      dispatch(updateConfig((state) => (state.activeId = numericValue)));
    }
  };


  return { formik, formData, getFieldError, data, activeId, handleAdd , handleClick};
};

export default useShowForm;
