import { useFormik } from "formik";
import { generateBulkEstimate, getEstimateList, getSubmitList } from "../../api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Billing/staffBillingSlice";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import * as Yup from "yup";

const useSubmitForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const billingState = useSelector((state) => state.staffBilling);

  const [isSpecialCase, setIsSpecialCase] = useState(false);
  const [addExtraService, setAddExtraService] = useState(false);
  const [buttonLoader,setButtonLoader] =useState(false)
  

  const formik = useFormik({
    initialValues: {
      estimate_type: billingState?.specialListData?.estimate_types?.[0]?._id,
      add_extra_service : addExtraService,
      item_services: "",
      service_status: [],
      service_order_type: "",
      service: billingState?.specialListData?.stock_services?.map((service) => ({
        service_id: service._id,
        order_type: "",
        status: [],
      })),
  
    },
    validate: (values) => {
      const errors = {};
    
    if (values?.estimate_type === 3 || values?.estimate_type === 4) {
      // Initialize an empty object to hold errors related to services
      if(values?.item_services !== "") {
        if (!values.service_order_type) {
          errors.service_order_type = "Order type is required";
        }
      
        if (values.service_status.length === 0) {
          errors.service_status = "Status is required";
        }
        
      } 
      errors.service = {};
  
      values?.service?.forEach((service, index) => {
        const serviceErrors = {};
  
        // Check if order_type is empty for the service
        if (!service.order_type) {
          serviceErrors.order_type = "Order type is required";
        }
  
        // Check if status is empty for the service
        if (service.status.length === 0) {
          serviceErrors.status = "Status is required";
        }
  
        // Only add errors for this service item if there are any
        if (Object.keys(serviceErrors).length > 0) {
          errors.service[index] = serviceErrors;
        }
      });
  
      // Remove the service key if there are no errors
      if (Object.keys(errors.service).length === 0) {
        delete errors.service;
      }
    }
    return errors;
    },
    
    onSubmit: (values) => {
      const result = createServiceObject(values);
      setButtonLoader(true)
      if(billingState?.generateBulk === false) {
        const params = {
          item_details_id: billingState?.selectedItems ?? [],
          estimate_type: result?.estimate_type,
          // service: result?.service
        };
        if(result?.estimate_type === 3 || result?.estimate_type === 4) {
          params.service = result?.service;
        }
   
        dispatch(
          updateConfig((state) => {
            state.isPending = true;
          })
        );
        getEstimateList(params).then((response) => {
          if (response?.data?.status_code === 200) {
            localStorage?.setItem("invoice_id", response?.data?.data?.estimate_id);
            dispatch(
              updateConfig((state) => {
                state.invoiceData = response?.data?.data;
                state.estimateModal = false;
                state.isPending = false;
                state.generateBulk = false;
              })
            );
            navigate("invoice");
            setButtonLoader(false)
          } else if (response?.status_code === 403) {
            toast.error(response?.message);
            dispatch(updateConfig((state) => {
              state.isPending = false;
              state.estimateModal = false;
          }));
            setButtonLoader(false)
          } else if (response?.status_code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields.forEach((field) => {
              toast.error(errors[field]?.[0]);
            });
            dispatch(updateConfig((state) => {
              state.isPending = false;
              state.estimateModal = false;
          }));
            setButtonLoader(false)
          } else {
            toast.error("Something went wrong");
            dispatch(updateConfig((state) => {
              state.isPending = false;
              state.estimateModal = false;
          }));
            setButtonLoader(false)
          }
        });
      }
      else {
        handleGenerateEstimate(result);
      }
      
    
    },
  });

  const handleGenerateEstimate = async (result) => {
    dispatch(updateConfig((state) => (state.isPending = true)));
    let data = {
      stock_receipt_id: billingState?.selectedBilling?.[0],
      status: billingState?.selectedStatus,
      is_bulk_estimate: true,
      estimate_type: result?.estimate_type,
      
    };
    if(result?.estimate_type === 3 || result?.estimate_type === 4) {
      data.service= result?.service;
    }
    const response = await generateBulkEstimate(data);
    try {
      if (response?.data?.status_code === 200) {
        localStorage?.setItem("invoice_id", response?.data?.data?.estimate_id);
        dispatch(
          updateConfig((state) => {
            state.invoiceData = response?.data?.data;
            state.estimateModal = false;
            state.isPending = false;
            state.generateBulk = false;
          })
        );
        toast.success(response?.data?.message);
        navigate("invoice");
      } else if (response?.status_code === 403) {
        toast.error(response?.message);
        dispatch(updateConfig((state) => (state.isPending = false)));
      } else if (response?.status_code === 422) {
        let errors = response?.errors;
        let errorFields = Object.keys(errors);
        errorFields.forEach((field) => {
          toast.error(errors[field]?.[0]);
        });
        dispatch(updateConfig((state) => (state.isPending = false)));
      } else {
        toast.error("Something went wrong");
        dispatch(updateConfig((state) => (state.isPending = false)));
      }
    } catch (error) {
      toast.error("Something went wrong");
      dispatch(updateConfig((state) => (state.isPending = false)));
    }
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleOrderTypeChange = (value, index) => {
    if(index !== null) {
      formik.setFieldValue(`service[${index}].order_type`, value?.id);
    }
    else {
      formik.setFieldValue(`service_order_type`, value?.id);
    }
    
  };

  const handleServiceChange = (value) => {
    formik.setFieldValue(`item_services`, value?._id);
  }

  const handleStatusChange = (value, index, setFieldValue) => {
    if(index !== null) {
      setFieldValue(
        `service[${index}].status`,
        value?.map((v) => v.id)
      );
    }
    else {
      setFieldValue(
        `service_status`,
        value?.map((v) => v.id)
      );
    }
    
  };

  const createServiceObject = (data) => {
    const result = { ...data }; // Copy the original data
  
    if (data?.add_extra_service) {
      const extraService = {
        service_id: data.item_services,
        order_type: data.service_order_type,
        status: data.service_status,
      };
  
      // Append the extra service object to the service array
      result.service = [...data.service, extraService];
    }
  
    return result;
  }
  

  return { formik, getFieldError, handleStatusChange, handleOrderTypeChange, specialData: billingState?.specialListData, isSpecialCase, setIsSpecialCase, addExtraService, setAddExtraService, handleServiceChange, buttonLoader, isPending: billingState?.isPending };
};

export default useSubmitForm;
