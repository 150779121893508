import React from "react";
import useShowForm from "./useShowForm";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";
import Style from "../printing.module.scss";
import { TabHeading } from "../../../Global/TabHeading";

const ShowForm = () => {
  const {
    formData,
    formik,
    getFieldError,
    data,
    activeId,
    showVersionTwoTable,
    handleClick,
    handleAdd,
    handleCompare,
    handleDownload,
    excelCopyPermission,
  } = useShowForm();
  
  return (
    // <div className={`col pro-pt-4 ${Style.table_main_wrapper}`}>
    //   <div
    //     className={`pro-w-100 pro-d-flex pro-flex-column ${Style.compare_inner_wrap}`}
    //   >
    //     {/* <h5 className="pro-ttl h5">{itemID}</h5> */}
    //     {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
    //     {/* <h6 className="pro-ttl h6">{certificateNumber}</h6> */}
    //     {/* qc fields */}
    //     <>
    //       <label
    //         htmlFor="qc_fields"
    //         className="pro-font-sm pro-mb-1 pro-fw-medium"
    //       >
    //         QC Fields
    //       </label>
    //       {formData?.qc_fields?.map((value, index) => {
    //         return (
    //           <>
    //             {value?.slug === "color" ||
    //             value?.slug === "clarity" ||
    //             value?.slug === "finish" ||
    //             value?.slug === "shape" ? (
    //               <div className="input-wrap pro-mb-4" key={index}>
    //                 <label
    //                   htmlFor=""
    //                   className="pro-font-sm pro-mb-1 pro-fw-medium"
    //                 >
    //                   {value?.name}
    //                 </label>
    //                 <Select
    //                   id={value?.slug}
    //                   placeholder={"Select"}
    //                   className={`pro-input lg  ${
    //                     getFieldError(`${value?.slug}`) && "error"
    //                   }`}
    //                   classNamePrefix="pro-input"
    //                   name={value?.slug}
    //                   options={formData?.[value?.slug]}
    //                   getOptionValue={(option) => option?._id}
    //                   getOptionLabel={(option) => option?.name}
    //                   onBlur={formik.handleBlur(`${value?.slug}`)}
    //                   value={
    //                     formData?.[value?.slug]?.filter(
    //                       (val) => val?.name === formik?.values?.[value?.slug]
    //                     )?.[0]
    //                   }
    //                   onChange={(e) =>
    //                     formik?.setFieldValue(`${value?.slug}`, e?.name)
    //                   }
    //                   menuPlacement="auto"
    //                 />
    //                 {getFieldError(`${value?.slug}`) && (
    //                   <span className="error-text">
    //                     {getFieldError(`${value?.slug}`)}
    //                   </span>
    //                 )}
    //               </div>
    //             ) : (
    //               <>
    //                 <Input
    //                   key={index}
    //                   label={value?.name}
    //                   type="text"
    //                   id={value?.slug}
    //                   name={value?.slug}
    //                   className={`pro-input lg ${
    //                     getFieldError(`${value?.slug}`) && " error"
    //                   }`}
    //                   onChange={(e) =>
    //                     formik?.setFieldValue(
    //                       `${value?.slug}`,
    //                       e?.target?.value
    //                     )
    //                   }
    //                   onBlur={formik?.handleBlur(value?.slug)}
    //                   error={getFieldError(value?.slug)}
    //                   errorMessage={getFieldError(value?.slug)}
    //                   value={formik?.values?.[value?.slug]}
    //                 />
    //               </>
    //             )}
    //           </>
    //         );
    //       })}
    //     </>

    //     {/* center stone */}
    //     {formData?.cstone_sstone === 1 && (
    //       <>
    //         <small>Center Stone Fields</small>
    //         {formData?.center_stone_fields?.map((value, index) => {
    //           return (
    //             <>
    //               {value?.slug === "color" ||
    //               value?.slug === "clarity" ||
    //               value?.slug === "finish" ||
    //               value?.slug === "shape" ? (
    //                 <div className="input-wrap pro-mb-4" key={index}>
    //                   <label
    //                     htmlFor=""
    //                     className="pro-font-sm pro-mb-1 pro-fw-medium"
    //                   >
    //                     {value?.name}
    //                   </label>
    //                   <Select
    //                     id={`center_stone_${value?.slug}`}
    //                     placeholder={"Select"}
    //                     className={`pro-input lg  ${
    //                       getFieldError(`center_stone_${value?.slug}`) &&
    //                       "error"
    //                     }`}
    //                     classNamePrefix="pro-input"
    //                     name={`center_stone_${value?.slug}`}
    //                     options={formData?.[value?.slug]}
    //                     getOptionValue={(option) => option?._id}
    //                     getOptionLabel={(option) => option?.name}
    //                     onBlur={formik.handleBlur(
    //                       `center_stone_${value?.slug}`
    //                     )}
    //                     value={
    //                       formData?.[value?.slug]?.filter(
    //                         (val) =>
    //                           val?.name ===
    //                           formik?.values?.[`center_stone_${value?.slug}`]
    //                       )?.[0]
    //                     }
    //                     onChange={(e) =>
    //                       formik?.setFieldValue(
    //                         `center_stone_${value?.slug}`,
    //                         e?.name
    //                       )
    //                     }
    //                     menuPlacement="auto"
    //                   />
    //                   {getFieldError(`center_stone_${value?.slug}`) && (
    //                     <span className="error-text">
    //                       {getFieldError(`center_stone_${value?.slug}`)}
    //                     </span>
    //                   )}
    //                 </div>
    //               ) : (
    //                 <Input
    //                   key={index}
    //                   label={value?.name}
    //                   type="text"
    //                   id={`center_stone_${value?.slug}`}
    //                   name={`center_stone_${value?.slug}`}
    //                   className={`pro-input lg ${
    //                     getFieldError(`${`center_stone_${value?.slug}`}`) &&
    //                     "error"
    //                   }`}
    //                   onChange={(e) =>
    //                     formik?.setFieldValue(
    //                       `${`center_stone_${value?.slug}`}`,
    //                       e?.target?.value
    //                     )
    //                   }
    //                   onBlur={formik?.handleBlur(`center_stone_${value?.slug}`)}
    //                   error={getFieldError(`center_stone_${value?.slug}`)}
    //                   errorMessage={getFieldError(
    //                     `center_stone_${value?.slug}`
    //                   )}
    //                   value={formik?.values?.[`center_stone_${value?.slug}`]}
    //                 />
    //               )}
    //             </>
    //           );
    //         })}
    //       </>
    //     )}
    //     {/* side stone */}
    //     {formData?.cstone_sstone === 1 && (
    //       <>
    //         <small>Side Stone Fields</small>
    //         {formData?.side_stone_fields?.map((value, index) => {
    //           return (
    //             <>
    //               {value?.slug === "color" ||
    //               value?.slug === "clarity" ||
    //               value?.slug === "finish" ||
    //               value?.slug === "shape" ? (
    //                 <div className="input-wrap pro-mb-4" key={index}>
    //                   <label
    //                     htmlFor=""
    //                     className="pro-font-sm pro-mb-1 pro-fw-medium"
    //                   >
    //                     {value?.name}
    //                   </label>
    //                   <Select
    //                     id={`side_stone_${value?.slug}`}
    //                     placeholder={"Select"}
    //                     className={`pro-input lg  ${
    //                       getFieldError(`side_stone_${value?.slug}`) && "error"
    //                     }`}
    //                     classNamePrefix="pro-input"
    //                     name={`side_stone_${value?.slug}`}
    //                     options={formData?.[value?.slug]}
    //                     getOptionValue={(option) => option?._id}
    //                     getOptionLabel={(option) => option?.name}
    //                     onBlur={formik.handleBlur(`side_stone_${value?.slug}`)}
    //                     value={
    //                       formData?.[value?.slug]?.filter(
    //                         (val) =>
    //                           val?.name ===
    //                           formik?.values?.[`side_stone_${value?.slug}`]
    //                       )?.[0]
    //                     }
    //                     onChange={(e) =>
    //                       formik?.setFieldValue(
    //                         `side_stone_${value?.slug}`,
    //                         e?.name
    //                       )
    //                     }
    //                     menuPlacement="auto"
    //                   />
    //                   {getFieldError(`side_stone_${value?.slug}`) && (
    //                     <span className="error-text">
    //                       {getFieldError(`side_stone_${value?.slug}`)}
    //                     </span>
    //                   )}
    //                 </div>
    //               ) : (
    //                 <Input
    //                   key={index}
    //                   label={value?.name}
    //                   type="text"
    //                   id={`side_stone_${value?.slug}`}
    //                   name={`side_stone_${value?.slug}`}
    //                   className={`pro-input lg ${
    //                     getFieldError(`${`side_stone_${value?.slug}`}`) &&
    //                     "error"
    //                   }`}
    //                   onChange={(e) =>
    //                     formik?.setFieldValue(
    //                       `${`side_stone_${value?.slug}`}`,
    //                       e?.target?.value
    //                     )
    //                   }
    //                   onBlur={formik?.handleBlur(`side_stone_${value?.slug}`)}
    //                   error={getFieldError(`side_stone_${value?.slug}`)}
    //                   errorMessage={getFieldError(`side_stone_${value?.slug}`)}
    //                   value={formik?.values?.[`side_stone_${value?.slug}`]}
    //                 />
    //               )}
    //             </>
    //           );
    //         })}
    //       </>
    //     )}
    //     <div
    //       className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn pro-py-4 ${Style.ftr_btn_wrap}`}
    //     >
    //       <Button
    //         className={"pro-btn-primary lg pro-ms-3"}
    //         type="submit"
    //         onClick={formik.handleSubmit}
    //       >
    //         Submit
    //       </Button>
    //     </div>
    //   </div>
    // </div>
    <>
      {/* {activeCard.key !== "Marked" && activeCard.key !== "Rejected" && ( */}
      <TabHeading
        data={data}
        uniqueId={"id"}
        activeId={activeId}
        handleClick={handleClick}
      />
      {/* )} */}
      {/* <div className={`pro-ms-4 pro-d-flex pro-flex-column ${Style.tabs_wrap}`}> */}
        {/* {activeId === 1 ? <VersionOne /> : <VersionTwo />} */}
        <div
          className={`pro-w-100 pro-d-flex pro-flex-column ${Style.compare_inner_wrap}`}
        >
          {/* <h5 className="pro-ttl h5">{itemID}</h5> */}
          {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
          {/* <h6 className="pro-ttl h6">{certificateNumber}</h6> */}
          {/* qc fields */}
          <>
            <label
              htmlFor="qc_fields"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              QC Fields
            </label>
            {formData?.qc_fields?.map((value, index) => {
              return (
                <>
                  {value?.slug === "color" ||
                  value?.slug === "clarity" ||
                  value?.slug === "finish" ||
                  value?.slug === "shape" ? (
                    <div className="input-wrap pro-mb-4" key={index}>
                      <label
                        htmlFor=""
                        className="pro-font-sm pro-mb-1 pro-fw-medium"
                      >
                        {value?.name}
                      </label>
                      <Select
                        id={value?.slug}
                        placeholder={"Select"}
                        className={`pro-input lg  ${
                          getFieldError(`${value?.slug}`) && "error"
                        }`}
                        classNamePrefix="pro-input"
                        name={value?.slug}
                        options={formData?.[value?.slug]}
                        getOptionValue={(option) => option?._id}
                        getOptionLabel={(option) => option?.name}
                        onBlur={formik.handleBlur(`${value?.slug}`)}
                        value={
                          formData?.[value?.slug]?.filter(
                            (val) => val?.name === formik?.values?.[value?.slug]
                          )?.[0]
                        }
                        onChange={(e) =>
                          formik?.setFieldValue(`${value?.slug}`, e?.name)
                        }
                        menuPlacement="auto"
                        isDisabled={activeId <= formData?.updated_values?.length}
                      />
                      {getFieldError(`${value?.slug}`) && (
                        <span className="error-text">
                          {getFieldError(`${value?.slug}`)}
                        </span>
                      )}
                    </div>
                  ) : (
                    <>
                      <Input
                        key={index}
                        label={value?.name}
                        type="text"
                        id={value?.slug}
                        name={value?.slug}
                        className={`pro-input lg ${
                          getFieldError(`${value?.slug}`) && " error"
                        }`}
                        onChange={(e) =>
                          formik?.setFieldValue(
                            `${value?.slug}`,
                            e?.target?.value
                          )
                        }
                        onBlur={formik?.handleBlur(value?.slug)}
                        error={getFieldError(value?.slug)}
                        errorMessage={getFieldError(value?.slug)}
                        value={formik?.values?.[value?.slug]}
                        disabled={activeId <= formData?.updated_values?.length}
                      />
                    </>
                  )}
                </>
              );
            })}
          </>

          {/* center stone */}
          {formData?.cstone_sstone === 1 && (
            <>
              <small>Center Stone Fields</small>
              {formData?.center_stone_fields?.map((value, index) => {
                return (
                  <>
                    {value?.slug === "color" ||
                    value?.slug === "clarity" ||
                    value?.slug === "finish" ||
                    value?.slug === "shape" ? (
                      <div className="input-wrap pro-mb-4" key={index}>
                        <label
                          htmlFor=""
                          className="pro-font-sm pro-mb-1 pro-fw-medium"
                        >
                          {value?.name}
                        </label>
                        <Select
                          id={`center_stone_${value?.slug}`}
                          placeholder={"Select"}
                          className={`pro-input lg  ${
                            getFieldError(`center_stone_${value?.slug}`) &&
                            "error"
                          }`}
                          classNamePrefix="pro-input"
                          name={`center_stone_${value?.slug}`}
                          options={formData?.[value?.slug]}
                          getOptionValue={(option) => option?._id}
                          getOptionLabel={(option) => option?.name}
                          onBlur={formik.handleBlur(
                            `center_stone_${value?.slug}`
                          )}
                          value={
                            formData?.[value?.slug]?.filter(
                              (val) =>
                                val?.name ===
                                formik?.values?.[`center_stone_${value?.slug}`]
                            )?.[0]
                          }
                          onChange={(e) =>
                            formik?.setFieldValue(
                              `center_stone_${value?.slug}`,
                              e?.name
                            )
                          }
                          menuPlacement="auto"
                          isDisabled={activeId <= formData?.updated_values?.length}
                        />
                        {getFieldError(`center_stone_${value?.slug}`) && (
                          <span className="error-text">
                            {getFieldError(`center_stone_${value?.slug}`)}
                          </span>
                        )}
                      </div>
                    ) : (
                      <Input
                        key={index}
                        label={value?.name}
                        type="text"
                        id={`center_stone_${value?.slug}`}
                        name={`center_stone_${value?.slug}`}
                        className={`pro-input lg ${
                          getFieldError(`${`center_stone_${value?.slug}`}`) &&
                          "error"
                        }`}
                        onChange={(e) =>
                          formik?.setFieldValue(
                            `${`center_stone_${value?.slug}`}`,
                            e?.target?.value
                          )
                        }
                        onBlur={formik?.handleBlur(
                          `center_stone_${value?.slug}`
                        )}
                        error={getFieldError(`center_stone_${value?.slug}`)}
                        errorMessage={getFieldError(
                          `center_stone_${value?.slug}`
                        )}
                        value={formik?.values?.[`center_stone_${value?.slug}`]}
                        disabled={activeId <= formData?.updated_values?.length}
                      />
                    )}
                  </>
                );
              })}
            </>
          )}
          {/* side stone */}
          {formData?.cstone_sstone === 1 && (
            <>
              <small>Side Stone Fields</small>
              {formData?.side_stone_fields?.map((value, index) => {
                return (
                  <>
                    {value?.slug === "color" ||
                    value?.slug === "clarity" ||
                    value?.slug === "finish" ||
                    value?.slug === "shape" ? (
                      <div className="input-wrap pro-mb-4" key={index}>
                        <label
                          htmlFor=""
                          className="pro-font-sm pro-mb-1 pro-fw-medium"
                        >
                          {value?.name}
                        </label>
                        <Select
                          id={`side_stone_${value?.slug}`}
                          placeholder={"Select"}
                          className={`pro-input lg  ${
                            getFieldError(`side_stone_${value?.slug}`) &&
                            "error"
                          }`}
                          classNamePrefix="pro-input"
                          name={`side_stone_${value?.slug}`}
                          options={formData?.[value?.slug]}
                          getOptionValue={(option) => option?._id}
                          getOptionLabel={(option) => option?.name}
                          onBlur={formik.handleBlur(
                            `side_stone_${value?.slug}`
                          )}
                          value={
                            formData?.[value?.slug]?.filter(
                              (val) =>
                                val?.name ===
                                formik?.values?.[`side_stone_${value?.slug}`]
                            )?.[0]
                          }
                          onChange={(e) =>
                            formik?.setFieldValue(
                              `side_stone_${value?.slug}`,
                              e?.name
                            )
                          }
                          menuPlacement="auto"
                          isDisabled={activeId <= formData?.updated_values?.length}
                        />
                        {getFieldError(`side_stone_${value?.slug}`) && (
                          <span className="error-text">
                            {getFieldError(`side_stone_${value?.slug}`)}
                          </span>
                        )}
                      </div>
                    ) : (
                      <Input
                        key={index}
                        label={value?.name}
                        type="text"
                        id={`side_stone_${value?.slug}`}
                        name={`side_stone_${value?.slug}`}
                        className={`pro-input lg ${
                          getFieldError(`${`side_stone_${value?.slug}`}`) &&
                          "error"
                        }`}
                        onChange={(e) =>
                          formik?.setFieldValue(
                            `${`side_stone_${value?.slug}`}`,
                            e?.target?.value
                          )
                        }
                        onBlur={formik?.handleBlur(`side_stone_${value?.slug}`)}
                        error={getFieldError(`side_stone_${value?.slug}`)}
                        errorMessage={getFieldError(
                          `side_stone_${value?.slug}`
                        )}
                        value={formik?.values?.[`side_stone_${value?.slug}`]}
                        disabled={activeId <= formData?.updated_values?.length}
                      />
                    )}
                  </>
                );
              })}
            </>
          )}
          {/* <div
            className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn pro-py-4 ${Style.ftr_btn_wrap}`}
          >
            <Button
              className={"pro-btn-primary lg pro-ms-3"}
              type="submit"
              onClick={formik.handleSubmit}
            >
              Submit
            </Button>
          </div> */}
        </div>
      {/* </div> */}
      {/* {activeCard.key !== "Marked" && activeCard.key !== "Rejected" && ( */}
      <div
        className={`col-12 pro-d-flex pro-justify-end pro-py-3 pro-gap-3 ${Style.footer_btn_wrap} `}
      >
        {activeId === 1 ? (
          <>
            {/* <Button
              type="button"
              className={"pro-btn-outline lg pro-px-5"}
              onClick={handleDownload}
            >
              <span className="material-symbols-outlined">
                download_for_offline
              </span>
              Download
            </Button>
            <Button
              className={"pro-btn-outline lg pro-px-5"}
              onClick={handleCompare}
            >
              Compare
            </Button> */}
            {activeId === 1 && (
              <>
                <Button
                  className={"pro-btn-outline lg "}
                  type="button"
                  onClick={handleAdd}
                >
                  Create a copy
                </Button>
              </>
            )}
          </>
        ) : (
           <div
            className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn pro-py-4 ${Style.ftr_btn_wrap}`}
          >
            <Button
              className={"pro-btn-primary lg pro-ms-3"}
              type="submit"
              onClick={formik.handleSubmit}
              disabled = {activeId <= formData?.updated_values?.length}
            >
              Update
            </Button>
          </div> 
        )}
      </div>
      {/* )} */}
    </>
  );
};

export default ShowForm;
